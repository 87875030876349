import { CountUp } from 'countup.js';
import Swiper, {FreeMode, Navigation, } from 'swiper';
import 'swiper/swiper.scss';
import ScrollReveal from 'scrollreveal'

const countUpOptions = { 
   useGrouping:true, 
   enableScrollSpy: true, 
   useEasing: true, suffix:"+", 
   scrollSpyDelay: 150, 
   scrollSpyOnce: true,
}
window.onload = function() {
  var countupProjects = new CountUp('countup-projects', 1830, countUpOptions);
  var countupManagement = new CountUp('countup-management', 220, countUpOptions);
  var countupAnalytics = new CountUp('countup-analytics', 390, countUpOptions);
  var countupCustomers = new CountUp('countup-customers', 834, countUpOptions);

  CountUp.handleScroll();

  countupProjects.start();
  countupManagement.start();
  countupAnalytics.start();
  countupCustomers.start();
  
}

const swiper = new Swiper('.swiper-reviews', {
   modules: [Navigation,FreeMode],
   
   slidesPerView: 1.25,
   spaceBetween: 16,
   freeMode: true,
   speed: 300,

   breakpoints: {
      920: {
         slidesPerView: 2.25,
         spaceBetween: 32,
         },
   },
   
   navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
   },
})
const swiperFeat = new Swiper('.swiper-feat', {
   modules: [FreeMode,],

   slidesPerView: 1.25,
   spaceBetween: 16,
   freeMode: true,
   speed: 300,
   
   breakpoints: {
      920: {
         slidesPerView: 3.5,
         spaceBetween: 32,
         },
   },
})
const swiperFeat2 = new Swiper('.swiper-feat2', {
   modules: [FreeMode,],

   slidesPerView: 1.25,
   spaceBetween: 16,
   freeMode: true,
   speed: 300,
   
   breakpoints: {
      920: {
         slidesPerView: 3.5,
         spaceBetween: 32,
         },
   },
})

ScrollReveal().reveal('.reveal', { delay: 150, duration: 750, distance: '10%', origin: 'bottom', opacity: 0, easing: 'ease-in' });

// Show/hide embeded Yt video
document.querySelectorAll('.showVid').forEach(btn => { btn.addEventListener("click", showVid); });
document.querySelector('.embeded-vid').addEventListener("click", showVid);
let hasVid = false;
function showVid() {
   document.querySelector(".overlay").classList.toggle("hide-overlay");
   hasVid ? 
   document.querySelector('.embeded-vid').innerHTML = "" : 
   document.querySelector('.embeded-vid').innerHTML = `<iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="788.54" height="443" type="text/html" src="https://www.youtube.com/embed/0odiWxmnFLQ?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0   &cc_load_policy=0&start=0&end=0&origin=http://youtubeembedcode.com"></iframe>` ;
   hasVid = !hasVid;

}